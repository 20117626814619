import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { envioBiometria, getRetornoAula } from "../services/apiService"; // Certifique-se de importar o getRetornoAula
import { AppContext } from "../context/AppContext";

import {
    Button,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    RadioGroup,
    Radio,
    Grid,
    FormControlLabel,
    FormControl,
    FormLabel,
    CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    loading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: theme.spacing(2),
        textAlign: "center",
        position: "relative",
    },
    title: {
        position: 'absolute',
        top: theme.spacing(2),
        width: '100%',
        textAlign: 'center',
    },
    content: {
        marginTop: theme.spacing(8),
        width: '100%',
        maxWidth: 600,
        textAlign: 'center',
    },
    photo: {
        maxWidth: "100%",
        maxHeight: "300px",
        marginBottom: theme.spacing(3),
    },
    formControl: {
        marginBottom: theme.spacing(3),
        width: '100%',
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));

const EnvioPage = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { capturedImage, codAulaPratica, selectedFinger, tipoRegistro } = useContext(AppContext);
    const [etapa, setEtapa] = useState(1);
    const [entradaRealizada, setEntradaRealizada] = useState(false);
    const [description, setDescription] = useState({
        titulo: "Aviso",
        mensagem: "Mensagem",
    });
  

    useEffect(() => {
        const buscarStatus = async () => {
            setLoading(true);
            try {
                const status = await getRetornoAula(codAulaPratica, selectedFinger);
                const entrada = status.find((s) => s.etapa === 1);
                if (entrada && entrada.dataHoraColeta) {
                    setEntradaRealizada(true);
                    setEtapa(2); // Seleciona automaticamente "Saída" se "Entrada" já foi feita
                }
            } catch (error) {
                console.error("Erro ao buscar status da biometria:", error);
            } finally {
                setLoading(false);
            }
        };

        buscarStatus();
    }, [codAulaPratica, selectedFinger]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = async () => {
        setLoading(true);
        try {
            const cleanedImage = capturedImage.replace(/^data:image\/[a-z]+;base64,/, "");
            const response = await envioBiometria(
                codAulaPratica,
                etapa,
                1,
                tipoRegistro,
                selectedFinger,
                cleanedImage
            );
            const { codigoRetornoBiometria, mensagemBiometria } = response;
            if (codigoRetornoBiometria === 1) {
                navigate('/sucesso', { state: { message: "Biometria realizada com sucesso!" }, replace: true });
            } else {
                const errorMessage = mensagemBiometria || "Erro desconhecido na biometria.";
                navigate('/falha', { state: { message: errorMessage }, replace: true });
            }
        } catch (error) {
            console.error("Erro no envio", error);
            navigate('/falha', { state: { message: error.response?.data?.message || error.message }, replace: true });
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <Typography variant="h3" component="h1" className={classes.title}>
                Enviar Biometria
            </Typography>
            <div className={classes.content}>
                {capturedImage && (
                    <img src={capturedImage} alt="Foto Capturada" className={classes.photo} />
                )}
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Qual etapa você está fazendo?</FormLabel>
                    <RadioGroup
                        aria-label="etapa"
                        name="etapa"
                        value={etapa}
                        onChange={(ev) => setEtapa(Number(ev.target.value))}
                    >
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            value={1}
                                            color="primary"
                                            disabled={entradaRealizada}
                                        />
                                    }
                                    label="Entrada"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Radio value={2} color="primary" />}
                                    label="Saída"
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
                <div className={classes.buttonContainer}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSend}
                        style={{ backgroundColor: 'green' }}
                    >
                        Enviar
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        size='large'
                        color="secondary"
                        onClick={() => navigate(-1)}
                    >
                        Refazer
                    </Button>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{description.titulo}</DialogTitle>
                <DialogContent>
                    <Typography>{description.mensagem}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EnvioPage;
