import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography, Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Typing from 'react-typing-effect';
import studentImage from './asset/image/icone4.png';
import instructorImage from './asset/image/icone1.png';
import companyLogo from './asset/image/logo512.png'; // Adicione o caminho para o logotipo da empresa

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start', // Alinha no topo
        minHeight: '100vh',
        textAlign: 'center',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4), // Espaço superior
    },
    logo: {
        width: 100,
        height: 100,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: 80,
            height: 80,
        },
    },
    welcomeText: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    button: {
        position: 'relative',
        width: 200,
        height: 200,
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#3f51b5',
        },
        [theme.breakpoints.down('sm')]: {
            width: 170,
            height: 170,
            marginTop: theme.spacing(1),
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(16),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: theme.spacing(10),
        },
    },
    image: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '70%',
        objectFit: 'cover',
        opacity: 0.9,
    },
    text: {
        position: 'absolute',
        bottom: theme.spacing(1),
        zIndex: 1,
        textAlign: 'center',
        width: '100%',
        fontWeight: 'bold',
    },
}));

const HomePage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [showSecondText, setShowSecondText] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSecondText(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Container className={classes.root}>
            <Box
                component="img"
                src={companyLogo}
                alt="Logotipo da Empresa"
                className={classes.logo}
            />
            <Typography variant={isMobile ? "h4" : "h3"} gutterBottom className={classes.welcomeText}>
                Bem Vindo ao Sistema Prático Web
            </Typography>
            {showSecondText && (
                <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>
                    <Typing
                        typingDelay={0}
                        eraseSpeed={0}
                        speed={20}
                        text="Por favor, selecione seu tipo de login:"
                    />
                </Typography>
            )}
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    onClick={() => navigate('/login')}
                >
                    <Box
                        component="img"
                        src={studentImage}
                        alt="Aluno"
                        className={classes.image}
                    />
                    <Typography variant="body1" className={classes.text}>Sou Aluno</Typography>
                </Button>
                <Button
                    className={classes.button}
                    onClick={() => navigate('/loginFuncionario')}
                >
                    <Box
                        component="img"
                        src={instructorImage}
                        alt="Funcionário"
                        className={classes.image}
                    />
                    <Typography variant="body1" className={classes.text}>Sou Instrutor</Typography>
                </Button>
            </div>
        </Container>
    );
};

export default HomePage;
