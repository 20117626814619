import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import HomePage from './HomePage';
import LoginPage from './pages/LoginPage';
import AulasPage from './pages/AulasPage';
import CapturePage from './pages/CapturePage';
import EnvioPage from './pages/EnvioPage';
import BiometriaSuccessPage from './pages/BiometriaSuccess';
import BiometriaFailurePage from './pages/BiometriaFailure';
import LoginFuncionarioPage from './pagesFuncionario/LoginFuncionarioPage';
import AulasPageFuncionario from './pagesFuncionario/AulasPageFuncionario';
import CaptureFuncionarioPage from './pagesFuncionario/CaptureFuncionarioPage';
import EnvioFuncionarioPage from './pagesFuncionario/EnvioFuncionarioPage';
import BiometriaSuccessFuncionarioPage from './pagesFuncionario/BiometriaSuccessFuncionarioPage';
import BiometriaFailureFuncionarioPage from './pagesFuncionario/BiometriaFailureFuncionarioPage';
import CaptureImagePage from './pages/CaptureImagePage';
import CaptureImageInstrutor from './pagesFuncionario/CaptureImageInstrutor';
import ProtectedRoute from './ProtectedRoute'; // Importe o componente ProtectedRoute

const App = () => {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/loginFuncionario" element={<LoginFuncionarioPage />} />
          <Route path="/aulas" element={<ProtectedRoute element={AulasPage} />} />
          <Route path="/capture" element={<ProtectedRoute element={CapturePage} />} />
          <Route path="/envio" element={<ProtectedRoute element={EnvioPage} />} />
          <Route path="/sucesso" element={<ProtectedRoute element={BiometriaSuccessPage} />} />
          <Route path="/falha" element={<ProtectedRoute element={BiometriaFailurePage} />} />
          <Route path="/aulas-funcionario" element={<ProtectedRoute element={AulasPageFuncionario} />} />
          <Route path="/capture-funcionario" element={<ProtectedRoute element={CaptureFuncionarioPage} />} />
          <Route path="/envio-funcionario" element={<ProtectedRoute element={EnvioFuncionarioPage} />} />
          <Route path="/sucesso-funcionario" element={<ProtectedRoute element={BiometriaSuccessFuncionarioPage} />} />
          <Route path="/falha-funcionario" element={<ProtectedRoute element={BiometriaFailureFuncionarioPage} />} />
          <Route path="/capture_image_aluno" element={<ProtectedRoute element={CaptureImagePage} />} />
          <Route path="/capture_image_instrutor" element={<ProtectedRoute element={CaptureImageInstrutor} />} />
        </Routes>
      </Router>
    </AppProvider>
  );
};

export default App;
