  // import React, { createContext, useState } from 'react';

  // export const AppContext = createContext();

  // export const AppProvider = ({ children }) => {
  //   const [codAulaPratica, setCodAulaPratica] = useState(null);
  //   const [codAluno, setCodAluno] = useState(null);
  //   const [capturedImage, setCapturedImage] = useState(null);
  //   const [selectedFinger, setSelectedFinger] = useState(3);
  //   const [tipoRegistro, setTipoRegistro] = useState(null);

  //   return (
  //     <AppContext.Provider value={{ codAulaPratica, setCodAulaPratica, codAluno, setCodAluno, capturedImage, setCapturedImage, selectedFinger, setSelectedFinger, tipoRegistro, setTipoRegistro }}>
  //       {children}
  //     </AppContext.Provider>
  //   );
  // };
  import React, { createContext, useState, useEffect } from 'react';

  export const AppContext = createContext();
  
  export const AppProvider = ({ children }) => {
    const [codAulaPratica, setCodAulaPratica] = useState(() => sessionStorage.getItem('codAulaPratica') || null);
    const [codAluno, setCodAluno] = useState(() => sessionStorage.getItem('codAluno') || null);
    const [capturedImage, setCapturedImage] = useState(() => sessionStorage.getItem('capturedImage') || null);
    const [selectedFinger, setSelectedFinger] = useState(() => Number(sessionStorage.getItem('selectedFinger')) || 3);
    const [tipoRegistro, setTipoRegistro] = useState(() => sessionStorage.getItem('tipoRegistro') || null);
  
    // Salvando o estado no sessionStorage quando eles mudam
    useEffect(() => {
      sessionStorage.setItem('codAulaPratica', codAulaPratica);
    }, [codAulaPratica]);
  
    useEffect(() => {
      sessionStorage.setItem('codAluno', codAluno);
    }, [codAluno]);
  
    useEffect(() => {
      sessionStorage.setItem('capturedImage', capturedImage);
    }, [capturedImage]);
  
    useEffect(() => {
      sessionStorage.setItem('selectedFinger', selectedFinger);
    }, [selectedFinger]);
  
    useEffect(() => {
      sessionStorage.setItem('tipoRegistro', tipoRegistro);
    }, [tipoRegistro]);
  
    return (
      <AppContext.Provider
        value={{
          codAulaPratica,
          setCodAulaPratica,
          codAluno,
          setCodAluno,
          capturedImage,
          setCapturedImage,
          selectedFinger,
          setSelectedFinger,
          tipoRegistro,
          setTipoRegistro,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  };
  