const Fingerprint = window.Fingerprint

const FingerprintSdk = (function () {
    function FingerprintSdk() {
        this.sdk = new Fingerprint.WebApi()

        this.sdk.onSamplesAcquired = function(s) {
            samplesAcquired(s)
        }
    }

    FingerprintSdk.prototype.getDeviceList = function () { return this.sdk.enumerateDevices() }

    FingerprintSdk.prototype.startCapture = function () {
        this.sdk.startAcquisition(Fingerprint.SampleFormat.PngImage).then(function () {
            return //console.log('Capturando')
        }, function (error) {
            return //console.log('Erro ao capturar')
        })
    }

    FingerprintSdk.prototype.stopCapture = function () {
        this.sdk.stopAcquisition().then(function () {
            return ////console.log('Captura de huella detenida')
        }, function (error) {
            return ////console.log('Error al detener la captura de huella')
        })
    }

    return FingerprintSdk
})()

// function samplesAcquired(s){   
//     // if(currentFormat === Fingerprint.SampleFormat.PngImage){   
//     // // If sample acquired format is PNG- perform following call on object received 
//     // // Get samples from the object - get 0th element of samples as base 64 encoded PNG image         
//         sessionStorage.setItem("imageSrc", "");                
//         let samples = JSON.parse(s.samples);            
//         sessionStorage.setItem("imageSrc", "data:image/png;base64," + Fingerprint.b64UrlTo64(samples[0]));
//         let vDiv = document.getElementById('imagediv');
//         vDiv.innerHTML = "";
//         let image = document.createElement("img");
//         image.id = "image";
//         image.src = sessionStorage.getItem("imageSrc");
//         vDiv.appendChild(image);
//     // }
// }
function samplesAcquired(s) {   
    // Limpa o sessionStorage para a nova imagem
    sessionStorage.setItem("imageSrc", "");                
    
    // Obtém os samples da resposta e converte o primeiro sample em PNG base64
    let samples = JSON.parse(s.samples);
    let pngDataUrl = "data:image/png;base64," + Fingerprint.b64UrlTo64(samples[0]);
    
    // Cria uma imagem a partir do Data URL PNG
    let image = new Image();
    image.src = pngDataUrl;

    image.onload = function() {
        // Cria um canvas para desenhar a imagem
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0);

        // Converte o conteúdo do canvas para JPEG e obtém o Data URL
        let jpegDataUrl = canvas.toDataURL("image/jpeg");

        // Armazena o JPEG no sessionStorage
        sessionStorage.setItem("imageSrc", jpegDataUrl);

        // Exibe a imagem JPEG na div
        let vDiv = document.getElementById('imagediv');
        vDiv.innerHTML = "";
        let jpegImage = document.createElement("img");
        jpegImage.id = "image";
        jpegImage.src = jpegDataUrl;
        vDiv.appendChild(jpegImage);
    };

    image.onerror = function() {
        console.error("Falha ao carregar a imagem PNG.");
    };
}

module.exports = { FingerprintSdk, Fingerprint }