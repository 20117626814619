import axios from "axios";
import { formatISO, subHours } from "date-fns";

// URL base para a API
const BASE_URL = "/2TSistemas";

// Cria uma instância do axios com a URL base
const api = axios.create({
  baseURL: BASE_URL,
});

// Obtém o token do armazenamento local
const getToken = () => {
  return sessionStorage.getItem("cod2");
};

// Formata a data e hora atuais
const getFormattedDateTime = () => {
  return formatISO(new Date(), { representation: "complete" });
};

// Chamada de API para login de aluno
export const login = async (login, password) => {
  const url = "/Login/Aluno";
  const response = await api.post(url, { login, senha: password });
  ////console.log(response)
  return response.data;
};

// Chamada de API para login de funcionário
export const loginFuncionario = async (login) => {
  const url = "/Login/Funcionario";
  const password = "nu;D3?4wO$P=8cj£8O`}NpZi*t?g";
  const response = await api.post(url, { login, senha: password });
  // ////console.log(response.data)
  return response.data;
};

function calcularHashSimples(str) {
  // str += 'Xurup1t4!';
  str += process.env.REACT_APP_ENVIO_KEY;
  let hash = 0;

  if (str.length === 0) return hash;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (((hash | 0) << 5) - hash) + char;
    hash = hash & hash; // Converter para 32 bits (opcional)
  }

  return hash;
}
// Chamada de API para enviar dados biométricos
export const envioBiometria = async (
  codigoAula,
  etapa,
  tipoPessoa,
  tipoRegistro,
  dedoColetado,
  image
) => {
  const url = `/AulaPratica/${codigoAula}/Biometria`;
  const token = getToken();
  const dataHora = getFormattedDateTime();
  const parametros = {
    codAulaPratica: codigoAula,
    tipoRegistro: tipoRegistro,
    tipoPessoa: tipoPessoa,
    dedoColetado: dedoColetado,
    etapa: etapa
  };
  // Converte o objeto `params` em uma string JSON
const paramsString = JSON.stringify(parametros);
  const hash = await calcularHashSimples(paramsString);
  const params = {
    codAulaPratica: codigoAula,
    tipoRegistro: tipoRegistro,
    tipoPessoa: tipoPessoa,
    dedoColetado: dedoColetado,
    etapa: etapa,
    imagem: image,
    hash: hash,
  };
  // console.log("Parametros enviados", params);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(params)
  const response = await api.post(url, params, config);
  // console.log(response);
  return response.data;
};
//curl--location 'https://localhost:5001/Totem/AulaPratica/Aluno/00199685231/2022-09-0107: 30:00 / 2022 -09-06 07: 30:00'//
// Chamada de API para obter aulas por CPF
//const url = `/Totem/AulaPratica/Instrutor/${cpf}/${dataInicio}/${dataFim}`;
export const getAulas = async (cpf) => {
  ////console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
  const formataData = new Intl.DateTimeFormat("pt-br", {
    timezone: "America/Sao_Paulo",
  });

// const dataAtual = new Date(); // Obtém a data atual
// dataAtual.setDate(dataAtual.getDate() - 1); // Subtrai 1 dia da data atual
// const dataFormatada = formataData.format(dataAtual); // Formata a nova data

  const dataFormatada = formataData.format(new Date());
  const dataHoje = dataFormatada.split("/").reverse().join("-");
  const dataInicio = dataHoje + "T04:00:00";
  const dataFim = dataHoje + "T23:59:00";
  const url = `/Totem/AulaPratica/Aluno/${cpf}/${dataInicio}/${dataFim}`;
  const token = getToken();

  // const params = { cpf, dataInicio, dataFim };
  const response = await api.get(url, {
    // params: params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  // console.log('Busca aulas', response.data);
  return response.data;
};

// Chamada de API para obter aulas por período
export const getAulasPeriodo = async (cpf) => {
  const url = "/AulaTeorica/Aluno";
  const token = getToken();
  const dataInicio = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  ).toISOString();
  const dataFim = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 2,
    0
  ).toISOString();
  const params = { cpf, dataInicio, dataFim };
  const response = await api.get(url, {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  ////console.log('PERIODO', response.data);
  return response.data;
};
//curl –location 'https://localhost:5001/Totem/AulaPratica/1112698/Aluno/BiometriasValidas'
// Chamada de API para obter dados biométricos de uma aula específica e CPF
export const getRetornoAula = async (codigo) => {
  const url = `/Totem/AulaPratica/${codigo}/Aluno/BiometriasValidas`;
  const token = getToken();
  const response = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  // console.log('Retorno Biometrias', response);
  return response.data;
};

// Chamada de API para obter aulas do instrutor por CPF e período
export const getAulasInstrutor = async (cpf, dataInicio, dataFim) => {
  // ////console.log(cpf, dataInicio, dataFim);
  const url = `/Totem/AulaPratica/Instrutor/${cpf}/${dataInicio}/${dataFim}`;
  const token = getToken();
  // ////console.log(token);
  const response = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  ////console.log('Aulas:', response.data);
  return response.data;
};

// Chamada de API para verificar a biometria de uma aula específica do instrutor por CPF
export const getBiometriaInstrutor = async (codigo) => {
  const url = `/Totem/AulaPratica/${codigo}/Instrutor/BiometriasValidas`;
  const token = getToken();
  const response = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  // console.log(response)
  return response.data;
};
