// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { login } from '../services/apiService';
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Container,
//   Paper,
//   CssBaseline,
//   Avatar,
// } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core";
// import logo from '../asset/image/icone4.png'; // Ajuste o caminho conforme necessário
// import CryptoJS from 'crypto-js';

// // Função de validação de CPF
// const isValidCPF = (cpf) => {
//   cpf = cpf.replace(/[^\d]+/g, ''); // Remove tudo que não é dígito
//   if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

//   let soma = 0;
//   let resto;
//   for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
//   resto = (soma * 10) % 11;
//   if ((resto === 10) || (resto === 11)) resto = 0;
//   if (resto !== parseInt(cpf.substring(9, 10))) return false;

//   soma = 0;
//   for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
//   resto = (soma * 10) % 11;
//   if ((resto === 10) || (resto === 11)) resto = 0;
//   if (resto !== parseInt(cpf.substring(10, 11))) return false;
//   return true;
// };

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '100vh',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   paper: {
//     padding: theme.spacing(4),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     width: theme.spacing(7),
//     height: theme.spacing(7),
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
//   errorMessage: {
//     color: 'red',
//   },
// }));

// const LoginPage = () => {
//   const classes = useStyles();
//   const [loginInput, setLoginInput] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   const handleLogin = async () => {
//     setError(''); // Reset error message
  
//     if (!isValidCPF(loginInput)) {
//       setError('CPF inválido!');
//       return;
//     }
  
//     try {
//       const data = await login(loginInput, password);
//       if (data.token) {
//         // Criptografar o CPF antes de armazenar
//         const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
//         const encryptedCPF = CryptoJS.AES.encrypt(loginInput, secretKey).toString();
  
//         sessionStorage.setItem('cod2', data.token);
//         sessionStorage.setItem('cod', encryptedCPF); // Armazena o CPF criptografado no sessionStorage
//         navigate('/aulas', { replace: true });
//       } else {
//         setError('Login falhou!');
//       }
//     } catch (error) {
//       console.error('Erro ao fazer login:', error);
//       setError('Erro ao fazer login. Verifique suas credenciais e tente novamente.');
//     }
//   };

//   const handleLoginChange = (e) => {
//     const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
//     setLoginInput(value);
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <CssBaseline />
//       <div className={classes.root}>
//         <Paper elevation={6} className={classes.paper}>
//           <Avatar alt="Logo" src={logo} className={classes.avatar} />
//           <Typography component="h1" variant="h5">
//             Fazer Login Aluno
//           </Typography>
//           <form className={classes.form} noValidate>
//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="CPF somente números"
//               name="email"
//               autoComplete="email"
//               autoFocus
//               value={loginInput}
//               onChange={handleLoginChange}
//             />
//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Senha (Provisoriamente o CPF)"
//               type="password"
//               id="password"
//               autoComplete="current-password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//             <Button
//               type="button"
//               fullWidth
//               variant="contained"
//               color="primary"
//               className={classes.submit}
//               onClick={handleLogin}
//             >
//               Entrar
//             </Button>
//             {error && <Typography className={classes.errorMessage}>{error}</Typography>}
//           </form>
//           <Box mt={5}>
//             <Typography variant="body2" color="textSecondary" align="center">
//               Em caso de dúvidas de como acessar, entre em contato com a sua Auto Escola!
//             </Typography>
//           </Box>
//         </Paper>
//       </div>
//     </Container>
//   );
// };

// export default LoginPage;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/apiService';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  CssBaseline,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import logo from '../asset/image/icone4.png'; // Ajuste o caminho conforme necessário
import CryptoJS from 'crypto-js';

// Função de validação de CPF
const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove tudo que não é dígito
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if ((resto === 10) || (resto === 11)) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: 'red',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const [loginInput, setLoginInput] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError(''); // Reset error message

    if (!isValidCPF(loginInput)) {
      setError('CPF inválido!');
      return;
    }

    if (!acceptTerms) {
      setError('Você deve aceitar os termos e condições para continuar.');
      return;
    }

    try {
      const data = await login(loginInput, password);
      if (data.token) {
        // Criptografar o CPF antes de armazenar
        const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
        const encryptedCPF = CryptoJS.AES.encrypt(loginInput, secretKey).toString();

        sessionStorage.setItem('cod2', data.token);
        sessionStorage.setItem('cod', encryptedCPF); // Armazena o CPF criptografado no sessionStorage
        navigate('/aulas', { replace: true });
      } else {
        setError('Login falhou!');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setError('Erro ao fazer login. Verifique suas credenciais e tente novamente.');
    }
  };

  const handleLoginChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    setLoginInput(value);
  };

  const handleTermsChange = () => {
    setAcceptTerms(!acceptTerms);
  };

  const handleOpenTermsModal = () => {
    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.root}>
        <Paper elevation={6} className={classes.paper}>
          <Avatar alt="Logo" src={logo} className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Fazer Login Aluno
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="CPF somente números"
              name="email"
              autoComplete="email"
              autoFocus
              value={loginInput}
              onChange={handleLoginChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha (Provisoriamente o CPF)"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            
            <Box mt={2} mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={handleTermsChange}
                    color="primary"
                  />
                }
                label={
                  <span>
                    Aceito os{" "}
                    <Button color="primary" onClick={handleOpenTermsModal}>
                      Termos e Condições
                    </Button>
                  </span>
                }
              />
            </Box>
            
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleLogin}
            >
              Entrar
            </Button>
            {error && <Typography className={classes.errorMessage}>{error}</Typography>}
          </form>
          <Box mt={5}>
            <Typography variant="body2" color="textSecondary" align="center">
              Em caso de dúvidas de como acessar, entre em contato com a sua Auto Escola!
            </Typography>
          </Box>
        </Paper>
      </div>

      {/* Modal de Termos e Condições */}
      <Dialog
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        aria-labelledby="terms-dialog-title"
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="terms-dialog-title">Termos e Condições</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <strong>1. ACEITAÇÃO</strong>
            <br />
            Este é um contrato firmado entre você, de agora em diante denominado como usuário, e a AJS TECNOLOGIA DE INFORMACAO EIRELI (2T SISTEMAS), empresa cadastrada no CNPJ sob nº 42.996.022/0001-01 com sede no município de Belém/Pa, e de agora em diante denominada simplesmente de 2T Biometria. Este “Termo de Uso de Aplicativo” rege o uso do presente aplicativo disponibilizado gratuitamente pela 2T Biometria seja para dispositivos móveis (Android, IOS, Windows Mobile), servidores, computadores pessoais (desktops) ou serviços web. Se você não concordar com estes termos não use este aplicativo. Você reconhece ainda que analisou e aceitou as condições de uso. Leia-as atentamente pois o uso deste aplicativo significa que você aceitou todos os termos e concorda em cumpri-los. Se você, usuário, for menor de idade ou declarado incapaz em quaisquer aspectos, precisará da permissão de seus pais ou responsáveis que também deverão concordar com estes mesmos termos e condições.
          </DialogContentText>
          <DialogContentText>
            <strong>2. LICENÇA LIMITADA</strong>
            <br />
            Você recebeu uma licença limitada, não transferível, não exclusiva, livre de royalties e revogável para baixar, instalar, executar e utilizar este aplicativo em seu dispositivo. Você reconhece e concorda que a 2T Biometria concede ao usuário uma licença exclusiva para uso e desta forma não lhe transfere os direitos sobre o produto. O aplicativo deverá ser utilizado por você, usuário. A venda, transferência, modificação, engenharia reversa ou distribuição bem como a cópia de textos, imagens ou quaisquer partes nele contido é expressamente proibida.
            No uso do aplicativo 2T Biometria, a coleta da biometria digital ou foto, o instrutor devidamente credenciado junto ao Detran/PA é quem deve efetuar o registro de coleta da biometria digital ou foto de seu respectivo aluno. Não sendo responsabilidade da 2T Sistemas a coleta inadequada.
            É do CFC usuário a completa responsabilidade do uso e manipulação do aplicativo 2T Biometria. Assim como liberação de Login e Senha de acesso para seus respectivos instrutores.
          </DialogContentText>

          <DialogContentText>
            <strong>3. ALTERAÇÕES, MODIFICAÇÕES E RESCISÃO</strong>
            <br />
            A 2T Biometria reserva-se no direito de, a qualquer tempo, modificar estes termos seja incluindo, removendo ou alterando quaisquer de suas cláusulas. Tais modificações terão efeito imediato. Após publicadas tais alterações, ao continuar com o uso do aplicativo, você terá aceitado e concordado em cumprir os termos modificados.
            O usuário não poderá responsabilizar a 2T Biometria nem seus diretores, executivos, funcionários, afiliados, agentes, contratados ou licenciadores por quaisquer modificações, suspensões ou descontinuidade do aplicativo.
            Você concorda que a 2T Biometria pode coletar e usar dados técnicos de seu dispositivo tais como especificações, configurações, versões de sistema operacional, tipo de conexão à internet e afins.
            O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio.
            Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua exclusiva responsabilidade manter os dados atualizados, bem como o usuário se compromete com a veracidade dos dados fornecidos
            O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.
            ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE
          </DialogContentText>
          {/* Continue com o restante das seções de acordo com o conteúdo fornecido */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTermsModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LoginPage;
