// FutronicCapture.jsx

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Grid, makeStyles } from "@material-ui/core";
import { AppContext } from "../context/AppContext";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import mao from "../asset/image/MaosParaCoeta.png"; // Atualize o caminho conforme necessário

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    textAlign: "center",
  },
  captureArea: {
    border: "2px dashed #000",
    width: "320px",
    height: "360px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px auto",
    borderRadius: "10px",
    position: "relative",
  },
  areaEscolha: {
    width: "600px",
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px auto",
    borderRadius: "10px",
    position: "relative",
  },
  handImage: {
    position: "relative",
    width: "100%",
    zIndex: 1,
  },
  selectionArea: {
    position: "absolute",
    border: "2px solid blue",
    borderRadius: "50%",
    opacity: 0.7,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "blue",
    fontWeight: "bold",
    width: "20px",
    height: "20px",
    zIndex: 3,
  },
  selected: {
    border: "2px solid green",
    color: "white",
    backgroundColor: "green",
  },
  instructionsText: {
    position: "absolute",
    top: "30px",
    width: "100%",
    textAlign: "center",
    color: "#333",
    fontSize: "1.5rem",
    zIndex: 4,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    height: "95%",
    objectFit: "contain",
    zIndex: "3",
  },
  captureText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "#000",
    fontSize: "1.5rem",
    zIndex: 2,
  },
  button: {
    color: "white",
    "&.capturar": {
      backgroundColor: "blue",
    },
    "&.prosseguir": {
      backgroundColor: "green",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "8px",
    maxWidth: "500px",
    width: "100%",
    textAlign: "center",
  },
  modalTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  modalContent: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeButton: {
    backgroundColor: "red",
    color: "white",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
}));

const FutronicCapture = () => {
  const {
    setCapturedImage,
    setTipoRegistro,
    selectedFinger,
    setSelectedFinger,
  } = useContext(AppContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [instructions, setInstructions] = useState(
    "Coloque o dedo sobre o leitor de impressão digital!"
  );
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [imageSrc, setImageSrc] = useState(""); // Armazena a imagem recebida via WebSocket
  const [socket, setSocket] = useState(null); // WebSocket
  const [isConnected, setIsConnected] = useState(false); // Verifica a conexão WebSocket
  const [captureStarted, setCaptureStarted] = useState(false); // Estado da captura

  // Token fixo fornecido
  const token =
    "f808817e93b178a17975e6b4605b8e2a960cac6dad2ece0d0dd5e83f94fd40aa";

  // Função para conectar ao WebSocket automaticamente ao carregar a página
  useEffect(() => {
    const newSocket = new WebSocket(`ws://localhost:5000/ws?token=${token}`);

    newSocket.onopen = () => {
      setIsConnected(true);
    };

    newSocket.onclose = () => {
      setIsConnected(false);
    };

    newSocket.onerror = (error) => {
      console.error("Erro no WebSocket:", error);
      setIsConnected(false);
      setOpen(true);
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.image && data.image.length > 0) {
        setImageSrc(`data:image/jpg;base64,${data.image}`);
      } else {
        setModalMessage("Erro: Imagem base64 está vazia.");
        setOpen(true);
      }
    };

    setSocket(newSocket);

    return () => {
      // Limpar a conexão ao sair da página
      if (newSocket) newSocket.close();
    };
  }, [token]);

  // Função para enviar comandos via WebSocket
  const sendWebSocketCommand = (command) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ command }));
    } else {
      setModalMessage("Erro: WebSocket não está conectado.");
      setOpen(true);
    }
  };

  const handleCaptureClick = () => {
    if (captureStarted) {
      // Se a captura já foi iniciada, para a captura e vai para a próxima página
      proceedToNextPage();
    } else {
      if (selectedFinger === null || selectedFinger === undefined) {
        setModalMessage(
          "Por favor, selecione um dedo antes de iniciar a captura."
        );
        setOpen(true);
        return;
      }

      // Iniciar captura
      sendWebSocketCommand("start_capture");
      setCaptureStarted(true);
      setInstructions("Captura iniciada. Coloque o dedo no leitor.");
    }
  };

  const proceedToNextPage = () => {
    if (imageSrc) {
      sendWebSocketCommand("stop_capture");
      setCapturedImage(imageSrc);
      setTipoRegistro(1);
      navigate("/envio-funcionario");
    } else {
      setModalMessage(
        "Por favor, capture uma impressão digital antes de prosseguir."
      );
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fingers = [
    { id: 0, label: "0", x: "6%", y: "28%" }, // Dedo mínimo esquerdo
    { id: 8, label: "8", x: "13%", y: "4%" }, // Dedo anelar esquerdo
    { id: 6, label: "6", x: "25%", y: "-7%" }, // Dedo médio esquerdo
    { id: 4, label: "4", x: "35%", y: "-1%" }, // Dedo indicador esquerdo
    { id: 2, label: "2", x: "47%", y: "36%" }, // Polegar esquerdo
    { id: 1, label: "1", x: "52%", y: "36%" }, // Polegar direito
    { id: 3, label: "3", x: "62%", y: "-1%" }, // Dedo indicador direito
    { id: 5, label: "5", x: "73%", y: "-7%" }, // Dedo médio direito
    { id: 7, label: "7", x: "86%", y: "4%" }, // Dedo anelar direito
    { id: 9, label: "9", x: "92%", y: "28%" }, // Dedo mínimo direito
  ];

  const selectFinger = (id) => {
    setSelectedFinger(id);
    setInstructions(
      `Dedo ${id} selecionado. Clique em capturar e coloque o dedo sobre o leitor de impressão digital!`
    );
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.instructionsText}>{instructions}</h1>
      <div className={classes.areaEscolha}>
        <img src={mao} alt="Imagem das Mãos" className={classes.handImage} />
        {fingers.map((finger) => (
          <div
            key={finger.id}
            className={`${classes.selectionArea} ${
              selectedFinger === finger.id ? classes.selected : ""
            }`}
            style={{ left: finger.x, top: finger.y }}
            onClick={() => selectFinger(finger.id)}
          >
            {finger.label}
          </div>
        ))}
      </div>
      <div className={classes.captureArea}>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="Imagem capturada"
            className={classes.imageContainer}
          />
        ) : (
          <div className={classes.captureText}>
            Clique em capturar e pressione o dedo sobre o leitor antes de
            prosseguir!
          </div>
        )}
      </div>
      <Grid
        container
        justifyContent="center"
        style={{ maxWidth: "600px", gap: "1rem", zIndex: 10, margin: "25px" }}
      >
        <Grid item xs={10} sm={5}>
          {/* <div>
                  <p>Caso já possua leitor conectado, instale o software necessário clicando no link abaixo, e atualize a pagina</p>
                  <a href={`${process.env.PUBLIC_URL}/digitalPersonaClient.Setup64.exe`} download="digitalPersonaClient.Setup64.exe">
                    Download do Software
                  </a>
                </div> */}
          <Button
            fullWidth
            variant="contained"
            size="large"
            className={`${classes.button} ${
              captureStarted ? "prosseguir" : "capturar"
            }`}
            onClick={handleCaptureClick}
            disabled={!isConnected}
          >
            {captureStarted ? "Prosseguir" : "Capturar"}
          </Button>
        </Grid>
      </Grid>
      <Modal
  className={classes.modal}
  open={open}
  onClose={handleClose}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 500,
  }}
>
  <Fade in={open}>
    <div className={classes.paper}>
      <h2 className={classes.modalTitle}>Problemas ao Capturar?</h2>
      <p style={{ textAlign: "justify" }}>
        Pode estar faltando um software necessário para que o leitor funcione
        corretamente. Siga as etapas abaixo de acordo com o leitor biométrico que possui!
        (Esta mensagem aparece apenas se não houver leitor conectado ou software instalado)
      </p>
      <div className={classes.modalContent}>
        <p style={{ textAlign: "justify" }}>{modalMessage}</p>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "15px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "400px",
            margin: "20px auto",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/futronic.png`}
            alt="Ícone"
            style={{
              width: "55px",
              height: "55px",
              marginRight: "15px",
            }}
          />
          <div>
            <h3>Leitor Futronic</h3>
            <p style={{ textAlign: "justify" }}>
              Caso já possua o leitor Futronic conectado, instale o software
              necessário clicando no link abaixo e atualize a página para coletar!
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/setup_scanner_futronic.exe`}
              download="setup_scanner_futronic.exe"
            >
              Download Futronic Software
            </a>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "15px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "400px",
            margin: "20px auto",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/persona.png`}
            alt="Ícone"
            style={{
              width: "75px",
              height: "75px",
              marginRight: "15px",
            }}
          />
          <div>
            <h3>Leitor Digital Persona</h3>
            <p style={{ textAlign: "justify" }}>
              Caso já possua o leitor Digital Persona conectado, instale o
              software necessário clicando no link abaixo e atualize a página.
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/digitalPersonaClient.Setup64.exe`}
              download="digitalPersonaClient.Setup64.exe"
            >
              Download Digital Persona Software
            </a>
          </div>
        </div>
      </div>
      <div className={classes.modalActions} style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
  <Button
    onClick={handleClose}
    variant="contained"
    className={classes.closeButton}
  >
    Fechar
  </Button>
</div>

    </div>
  </Fade>
</Modal>

    </div>
  );
};

export default FutronicCapture;
