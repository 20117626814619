import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Container, Typography, makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
    },
    icon: {
        fontSize: '4rem',
        color: 'green',
        marginBottom: theme.spacing(2),
    },
}));

const BiometriaSuccessPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { message } = location.state || { message: "Imagem enviada com sucesso!" };
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        const timeout = setTimeout(() => {
            navigate('/aulas');
        }, 5000);

        return () => {
            clearInterval(timer);
            clearTimeout(timeout);
        };
    }, [navigate]);

    useEffect(() => {
        if (countdown === 0) {
            navigate('/aulas');
        }
    }, [countdown, navigate]);

    return (
        <Container className={classes.root}>
            <CheckCircleOutlineIcon className={classes.icon} />
            <Typography variant="h4" gutterBottom>
                Sucesso!
            </Typography>
            <Typography style={{ color: 'green', fontSize: '37px' }}>
                {message}
            </Typography>
            <Typography variant="body1">
                Você será redirecionado para a lista de aulas em {countdown} segundos.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => navigate('/aulas')}
            >
                Voltar para a Lista de Aulas
            </Button>
        </Container>
    );
};

export default BiometriaSuccessPage;
