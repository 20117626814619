import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import { Container, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../context/AppContext';

const useStyles = makeStyles((theme) => ({
    captureContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        position: 'relative',
    },
    webcamContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        alignContent: 'center',
    },
    webcam: {
        width: '100%',
        height: '75%',
        objectFit: 'cover',
        transform: 'scaleX(-1)', // Espelha o vídeo horizontalmente
    },
    mask: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '88%',
        aspectRatio: '2 / 3',
        borderRadius: '50%',
        zIndex: 2,
        boxShadow: '0 0 0 2000px rgba(255, 255, 255, 1)',
        transform: 'translate(-50%, -50%)',
        border: '15px solid transparent',
        transition: 'border-color 0.3s',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            top: '55%',
        },
    },
    instructions: {
        position: 'absolute',
        top: '2%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '90%',
        color: 'yellow',
        textShadow: '1px 1px 1px black',
        fontWeight: 'bold',
        zIndex: 3,
        [theme.breakpoints.down('md')]: {
            top: '8%',
            fontSize: '30px',
        },
    },
    bottomButtonContainer: {
        position: 'absolute',
        top: '89%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: '100%',
        height: '10%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 4,
        [theme.breakpoints.down('md')]: {
            top: '90%',
        },
    },
    captureButton: {
        width: '50%',
        padding: '15px 20px',
        margin: '15px',
        height: '50px',
        fontSize: '20px',
        [theme.breakpoints.down('md')]: {
            height: '50px',
            fontSize: '20px',
        },
    },
}));

const CaptureImageInstrutor = () => {
    const classes = useStyles();
    const webcamRef = useRef(null);
    const navigate = useNavigate();
    const { capturedImage, setCapturedImage, codAulaTeorica, setTipoRegistro } = useContext(AppContext);
    const [instructions, setInstructions] = useState('Vamos lá, prepare-se para começar.');
    const [maskBorderColor, setMaskBorderColor] = useState('transparent');

    useEffect(() => {
        const loadModels = async () => {
            await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
            await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
        };
        loadModels();
    }, [codAulaTeorica]);

    const handleFaceDetection = async () => {
        const video = webcamRef.current?.video;
        if (!video) return [];
        const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
        return detections;
    };

    const isFaceWithinBounds = (landmarks) => {
        const video = webcamRef.current?.video;
        if (!video) return false;
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        const maskHeight = 0.8 * videoHeight;
        const maskWidth = (2 / 3) * maskHeight;
        const maskRadiusX = maskWidth / 2;
        const maskRadiusY = maskHeight / 2;

        const maskCenterX = videoWidth / 2;
        const maskCenterY = videoHeight / 2;

        const withinBounds = landmarks.positions.every(
            (point) =>
                Math.pow(point.x - maskCenterX, 2) / Math.pow(maskRadiusX, 2) +
                Math.pow(point.y - maskCenterY, 2) / Math.pow(maskRadiusY, 2) <= 1.5
        );

        if (withinBounds) {
            setMaskBorderColor('green');
            setInstructions('Rosto centralizado, captura permitida!\n\n Clique no botão "Capturar"');
        } else {
            setMaskBorderColor('red');
            setInstructions('Por favor, centralize seu rosto no centro da máscara!');
        }

        return withinBounds;
    };

    const checkFacePosition = async () => {
        const video = webcamRef.current?.video;
        if (!video) return;
        const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();

        if (detections) {
            isFaceWithinBounds(detections.landmarks);
        } else {
            setMaskBorderColor('red');
            setInstructions('Por favor, encaixe seu rosto no centro da máscara.');
        }
    };

    useEffect(() => {
        const intervalId = setInterval(checkFacePosition, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const handleCapture = async () => {
        const video = webcamRef.current?.video;
        if (!video) return;
        const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();

        if (detections && isFaceWithinBounds(detections.landmarks)) {
            const imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc);
            setInstructions('Captura concluída. Clique para confirmar.');
            setTipoRegistro(2);
            navigate('/envio-funcionario', { state: { capturedImage: imageSrc, codAulaTeorica } });
        } else {
            setInstructions('Por favor, encaixe seu rosto no centro da máscara.');
        }
    };

    const handleRetake = () => {
        setCapturedImage(null);
        setInstructions('Vamos lá, prepare-se para começar.');
        setMaskBorderColor('transparent');
    };

    return (
        <Container maxWidth="sm" className={classes.captureContainer}>
            <Typography variant="h4" className={classes.instructions} style={{ color: maskBorderColor }}>
                {instructions}
            </Typography>
            <Box className={classes.webcamContainer}>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className={classes.webcam}
                />
                <Box className={classes.mask} style={{ borderColor: maskBorderColor }} />
            </Box>
            <Box className={classes.bottomButtonContainer}>
                <Button variant="contained" color="primary" onClick={handleCapture} className={classes.captureButton}>
                    Capturar
                </Button>
            </Box>
        </Container>
    );
};

export default CaptureImageInstrutor;

//para colocar modal de botões
// import React, { useRef, useState, useEffect, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Webcam from 'react-webcam';
// import * as faceapi from 'face-api.js';
// import {
//     Container,
//     Box,
//     Typography,
//     Button,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     FormControlLabel,
//     Checkbox,
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import { AppContext } from '../context/AppContext';

// const useStyles = makeStyles((theme) => ({
//     captureContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         height: '100vh',
//         textAlign: 'center',
//         position: 'relative',
//     },
//     webcamContainer: {
//         position: 'relative',
//         width: '100%',
//         height: '100%',
//         maxWidth: '100%',
//         alignContent: 'center',
//     },
//     webcam: {
//         width: '100%',
//         height: '75%',
//         objectFit: 'cover',
//         transform: 'scaleX(-1)', // Espelha o vídeo horizontalmente
//     },
//     mask: {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         width: '88%',
//         aspectRatio: '2 / 3',
//         borderRadius: '50%',
//         zIndex: 2,
//         boxShadow: '0 0 0 2000px rgba(255, 255, 255, 1)',
//         transform: 'translate(-50%, -50%)',
//         border: '15px solid transparent',
//         transition: 'border-color 0.3s',
//         [theme.breakpoints.down('md')]: {
//             width: '80%',
//             top: '55%',
//         },
//     },
//     instructions: {
//         position: 'absolute',
//         top: '2%',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         width: '90%',
//         color: 'yellow',
//         textShadow: '1px 1px 1px black',
//         fontWeight: 'bold',
//         zIndex: 3,
//         [theme.breakpoints.down('md')]: {
//             top: '8%',
//             fontSize: '30px',
//         },
//     },
//     bottomButtonContainer: {
//         position: 'absolute',
//         top: '89%',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         width: '100%',
//         maxWidth: '100%',
//         height: '10%',
//         display: 'flex',
//         justifyContent: 'center',
//         zIndex: 4,
//         [theme.breakpoints.down('md')]: {
//             top: '90%',
//         },
//     },
//     captureButton: {
//         width: '50%',
//         padding: '15px 20px',
//         margin: '15px',
//         height: '50px',
//         fontSize: '20px',
//         [theme.breakpoints.down('md')]: {
//             height: '50px',
//             fontSize: '20px',
//         },
//     },
// }));

// const CaptureImageInstrutor = () => {
//     const classes = useStyles();
//     const webcamRef = useRef(null);
//     const navigate = useNavigate();
//     const { capturedImage, setCapturedImage, codAulaTeorica, setTipoRegistro } = useContext(AppContext);
//     const [instructions, setInstructions] = useState('Vamos lá, prepare-se para começar.');
//     const [maskBorderColor, setMaskBorderColor] = useState('transparent');
//     const [confirmationOpen, setConfirmationOpen] = useState(true);
//     const [checkboxes, setCheckboxes] = useState({
//         iluminacao: false,
//         semOculos: false,
//         semMascara: false,
//         semBone: false,
//     });

//     // useEffect(() => {
//     //     handleRetake();
//     // }, []);

//     useEffect(() => {
//         const loadModels = async () => {
//             await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
//             await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
//         };
//         loadModels();
//     }, [codAulaTeorica]);

//     const handleFaceDetection = async () => {
//         const video = webcamRef.current?.video;
//         if (!video) return [];
//         const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
//         return detections;
//     };

//     const isFaceWithinBounds = (landmarks) => {
//         const video = webcamRef.current?.video;
//         if (!video) return false;
//         const videoWidth = video.videoWidth;
//         const videoHeight = video.videoHeight;

//         const maskHeight = 0.8 * videoHeight;
//         const maskWidth = (2 / 3) * maskHeight;
//         const maskRadiusX = maskWidth / 2;
//         const maskRadiusY = maskHeight / 2;

//         const maskCenterX = videoWidth / 2;
//         const maskCenterY = videoHeight / 2;

//         const withinBounds = landmarks.positions.every(
//             (point) =>
//                 Math.pow(point.x - maskCenterX, 2) / Math.pow(maskRadiusX, 2) +
//                 Math.pow(point.y - maskCenterY, 2) / Math.pow(maskRadiusY, 2) <= 1.5
//         );

//         if (withinBounds) {
//             setMaskBorderColor('green');
//             setInstructions('Rosto centralizado, captura permitida!\n\n Clique no botão "Capturar"');
//         } else {
//             setMaskBorderColor('red');
//             setInstructions('Por favor, centralize seu rosto no centro da máscara!');
//         }

//         return withinBounds;
//     };

//     const checkFacePosition = async () => {
//         const video = webcamRef.current?.video;
//         if (!video) return;
//         const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();

//         if (detections) {
//             isFaceWithinBounds(detections.landmarks);
//         } else {
//             setMaskBorderColor('red');
//             setInstructions('Por favor, encaixe seu rosto no centro da máscara.');
//         }
//     };

//     useEffect(() => {
//         const intervalId = setInterval(checkFacePosition, 1000);
//         return () => clearInterval(intervalId);
//     }, []);

//     const handleCapture = async () => {
//         if (Object.values(checkboxes).every(value => value === true)) {
//             const video = webcamRef.current?.video;
//             if (!video) return;
//             const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();

//             if (detections && isFaceWithinBounds(detections.landmarks)) {
//                 const imageSrc = webcamRef.current.getScreenshot();
//                 setCapturedImage(imageSrc);
//                 setInstructions('Captura concluída. Clique para confirmar.');
//                 setTipoRegistro(2);
//                 setConfirmationOpen(false);  // Fechando o modal após captura
//                 navigate('/envio-funcionario', { state: { capturedImage: imageSrc, codAulaTeorica } });
//             } else {
//                 setInstructions('Por favor, encaixe seu rosto no centro da máscara.');
//             }
//         } else {
//             alert('Você deve confirmar que está seguindo todas as orientações.');
//             setConfirmationOpen(true);
//         }
//     };

//     const handleCheckboxChange = (event) => {
//         setCheckboxes({
//             ...checkboxes,
//             [event.target.name]: event.target.checked,
//         });
//     };

//     const handleConfirmationClose = () => {
//         setConfirmationOpen(false);
//     };

//     return (
//         <Container maxWidth="sm" className={classes.captureContainer}>
//             <Typography variant="h4" className={classes.instructions} style={{ color: maskBorderColor }}>
//                 {instructions}
//             </Typography>
//             <Box className={classes.webcamContainer}>
//                 <Webcam
//                     audio={false}
//                     ref={webcamRef}
//                     screenshotFormat="image/jpeg"
//                     className={classes.webcam}
//                 />
//                 <Box className={classes.mask} style={{ borderColor: maskBorderColor }} />
//             </Box>
//             <Box className={classes.bottomButtonContainer}>
//                 <Button variant="contained" color="primary" onClick={handleCapture} className={classes.captureButton}>
//                     Capturar
//                 </Button>
//             </Box>

//             <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
//                 <DialogTitle>Confirmação de Condições</DialogTitle>
//                 <DialogContent>
//                     <Typography>Para realizar a captura da biometria, por favor confirme que você:</Typography>
//                     <FormControlLabel
//                         control={<Checkbox checked={checkboxes.iluminacao} onChange={handleCheckboxChange} name="iluminacao" />}
//                         label="Está em um local bem iluminado"
//                     />
//                     <FormControlLabel
//                         control={<Checkbox checked={checkboxes.semOculos} onChange={handleCheckboxChange} name="semOculos" />}
//                         label="Não está usando óculos"
//                     />
//                     <FormControlLabel
//                         control={<Checkbox checked={checkboxes.semMascara} onChange={handleCheckboxChange} name="semMascara" />}
//                         label="Não está usando máscara"
//                     />
//                     <FormControlLabel
//                         control={<Checkbox checked={checkboxes.semBone} onChange={handleCheckboxChange} name="semBone" />}
//                         label="Não está usando boné"
//                     />
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleConfirmationClose} color="primary">Cancelar</Button>
//                     <Button onClick={() => setConfirmationOpen(false)} color="primary">Confirmar</Button>
//                 </DialogActions>
//             </Dialog>
//         </Container>
//     );
// };

// export default CaptureImageInstrutor;
